<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.operationAddState = true" style="margin-bottom: 10px">添加操作参数</el-button>
      <el-table
          :data="table.operationList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'descending'}"
          style="width: 100%">
        <el-table-column label="操作名称" align="center" #default="scope">
          {{scope.row.operationName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center"/>
        <el-table-column label="是否系统默认" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isDefault"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="warning" size="mini" v-if="scope.row.isDefault!==1" @click="openEdit(scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <operationAdd v-if="dialog.operationAddState" :state="dialog.operationAddState" @close="close"/>
    <operationEdit v-if="dialog.operationEditState" :operation="dialog.operation" :state="dialog.operationEditState" @close="close"/>
  </div>
</template>

<script>
import operationAdd from "@/pages/parameter/operation/operation-add";
import operationEdit from "@/pages/parameter/operation/operation-edit";

export default {
  name: "operation-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        operationList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        operationAddState: false,
        operationEditState: false,
        operation: {},
      }

    }
  },
  components: {
    operationAdd, operationEdit,
  },
  created() {
    this.queryOperationList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryOperationList();
    },
    //查询客户列表
    queryOperationList: function () {
      this.$axios({
        method: "GET",
        url: "/operation/queryOperationList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.operationList = response.data.data.list;
      })
    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.operationEditState = true
      this.dialog.operation = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryOperationList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryOperationList()
    },
    close: function (state) {
      this.dialog.operationAddState = state;
      this.dialog.operationEditState = state;
      this.queryOperationList();
    },
    isChange(val) {
      this.$axios({
        method: 'post',
        url: "/operation/updateOperation",
        params: {
          id: val.id,
          isDefault: val.isDefault,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '修改成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.queryOperationList()
      })
    }
  }
}
</script>

<style scoped>
</style>